<template>
	<div id="ClubWorkPlan_detail" v-loading="loading" :style="{ height: height + 'px' }">
		<div class="head u-f-item u-f-jsb">
			<div>{{ title }}</div>
		</div>
		<div class="content">
			<div class="title u-f-item u-f-jsb">
				<div>
					共{{ total }}条数据
					<!-- <span class="btn" @click="selectAll">选择全部</span> -->
				</div>
				<div class="u-f-item">
					<!-- <el-input placeholder="请输入学生姓名、学号" v-model="sousuo" size="small" style="margin-right: 10px;" @change="sousuochange">
                        <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    </el-input> -->
					<el-button size="small" @click="add">添加计划</el-button>
				</div>
			</div>
			<el-table
				:data="list"
				height="87%"
				ref="multipleTable"
				tooltip-effect="dark"
				style="width: 100%;border: 1px solid #EEEEEE;"
				key="2"
			>
				<el-table-column label="序号" type="index" width="70" align="center"></el-table-column>
				<el-table-column label="日期" prop="date" align="center"></el-table-column>
				<el-table-column label="时间" prop="time" align="center"></el-table-column>
				<el-table-column label="课时" prop="hour" align="center"></el-table-column>
				<el-table-column label="地点" prop="place" align="center"></el-table-column>
				<el-table-column label="活动内容" prop="content" align="center"></el-table-column>
				<el-table-column label="活动目标" prop="target" align="center"></el-table-column>
				<el-table-column label="操作" width="100" align="center">
					<template  slot-scope="scope">
						<el-button  type="text" size="small" @click="eidt(scope.row)">编辑</el-button>
						<el-button  type="text" @click="delStu(scope.row.id)" size="small">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="u-f-item u-f-right" style="padding: 15px 0;">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page.sync="page"
					:page-size="limit"
					layout="total, prev, pager, next"
					:total="total"
				></el-pagination>
			</div>
		</div>

		<!-- 添加计划 -->
		<el-dialog title="添加计划" :visible.sync="addShow" width="500px" :before-close="handleClose">
			<div class="edit-box">
				<div class="u-f-item input-box">
					<div class="input-lebal" style="text-align: right;width: 80px;">
						<span>*</span>
						日期
					</div>
					<el-date-picker value-format="yyyy-MM-dd" v-model="date" type="date" placeholder="选择日期"></el-date-picker>
				</div>
			</div>
			<div class="edit-box">
				<div class="u-f-item input-box">
					<div class="input-lebal" style="text-align: right;width: 80px;">
						<span>*</span>
						时间
					</div>
					<el-time-select v-model="time" :picker-options="{start: '08:30',step: '00:15',end: '18:30'}" placeholder="选择时间"></el-time-select>
				</div>
			</div>
			<div class="edit-box">
				<div class="u-f-item input-box">
					<div class="input-lebal" style="text-align: right;width: 80px;">
						<span>*</span>
						课时
					</div>
					<el-input v-model="hour" placeholder="请输入课时" style="width: 70%;"></el-input>
				</div>
			</div>
			<div class="edit-box">
				<div class="u-f-item input-box">
					<div class="input-lebal" style="text-align: right;width: 80px;">
						<span>*</span>
						地点
					</div>
					<el-input v-model="place" placeholder="请输入地点" style="width: 70%;"></el-input>
				</div>
			</div>
			<div class="edit-box">
				<div class="u-f input-box">
					<div class="input-lebal" style="text-align: right;width: 80px;margin-top: 5px;">
						<span>*</span>
						活动内容
					</div>
					<el-input type="textarea" v-model="content" placeholder="请输入活动内容" style="width: 70%;"></el-input>
				</div>
			</div>
			<div class="edit-box">
				<div class="u-f-item input-box">
					<div class="input-lebal" style="text-align: right;width: 80px;">
						<span>*</span>
						活动目标
					</div>
					<el-input v-model="target" placeholder="请输入活动目标" style="width: 70%;"></el-input>
				</div>
			</div>
			<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="colseShow" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click="submit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {},
	data() {
		return {
			height: 500,
			loading: false,
			id: '',
			page: 1,
			limit: 14,
			total: 0,
			list: [],
			title: '',
			addShow: false,
			date: '',
			time: '',
			hour:'',
			place:'',
			content:'',
			target:'',
			edit_id:'',
			type:1
		};
	},
	created() {},
	mounted() {
		this.height = document.documentElement.clientHeight || document.body.clientHeight;
		this.id = this.$route.query.id;
		this.title = this.$route.query.title;
		this.getList();
	},
	methods: {
		add(){
			this.type = 1;
			this.addShow = true;
		},
		eidt(item){
			this.type = 2;
			this.date = item.date;
			this.time = item.time;
			this.hour = item.hour;
			this.place = item.place;
			this.content = item.content;
			this.target = item.target;
			this.edit_id = item.id;
			this.addShow = true;
		},
		init(){
			this.date = '';
			this.time = '';
			this.hour = '';
			this.place = '';
			this.content = '';
			this.target = '';
			this.edit_id = '';
		},
		colseShow(){
			this.init()
			this.addShow = false;
		},
		// 关闭弹窗
		handleClose(done) {
			done();
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getList();
		},
		getList() {
			let data = {
				page: this.page,
				limit: this.limit,
				filter: JSON.stringify({ work_plan_id: this.id })
			};
			this.$api.station.getWorkPlanDetailList(data).then(res => {
				if (res.data.code == 1) {
					let list = res.data.data.rows;
					this.total = res.data.data.total;
					this.list = list;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 添加、编辑计划
		submit() {
			if (this.type == 1) {
				this.addWorkPlanDetail();
			} else if (this.type == 2) {
				this.editWorkPlanDetail()
			}
		},
		// 添加计划
		addWorkPlanDetail() {
			this.$api.station.addWorkPlanDetail({
				work_plan_id: this.id,
				date: this.date,
				time:this.time,
				hour:this.hour,
				place:this.place,
				content:this.content,
				target:this.target
			}).then(res => {
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.getList();
					this.init()
					this.addShow = false;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 编辑计划
		editWorkPlanDetail() {
			this.$api.station.editWorkPlanDetail({
				work_plan_id: this.id,
				date: this.date,
				time:this.time,
				hour:this.hour,
				place:this.place,
				content:this.content,
				target:this.target,
				id:this.edit_id
			}).then(res => {
				if (res.data.code == 1) {
					this.$message.success('修改成功');
					this.getList();
					this.init()
					this.addShow = false;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		delStu(id) {
			this.$confirm('确定要删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.$api.station
					.delWorkPlanDetail({
						id: id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.getList();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			});
		}
	}
};
</script>

<style lang="scss" scope>
#ClubWorkPlan_detail {
	.head {
		background-color: #4998ff;
		padding: 15px 20px;
		div {
			color: #fff;
			font-size: 16px;
		}
	}
	.content {
		background-color: #ffffff;
		margin-top: 20px;
		padding: 0 30px;
		height: calc(100% - 100px);
		overflow-y: auto;
		.title {
			padding: 0 0 15px 0;
			.btn {
				background-color: #f5f5f5;
				padding: 5px;
			}
			.btn:hover {
				color: #4998ff;
				background: #e4f1ff;
				cursor: pointer;
			}
		}
		.el-table th > .cell,
		.el-table .cell {
			overflow: hidden; /*超出部分隐藏*/
			white-space: nowrap; /*不换行*/
			text-overflow: ellipsis; /*超出部分文字以...显示*/
		}
		.el-table thead {
			background: #fafafa !important;
		}
		.el-table th {
			padding: 15px 5px !important;
			background: #fafafa !important;
		}
		.el-table::before {
			left: 0;
			bottom: 0;
			width: 100%;
			height: 0;
		}
		.el-table .btn {
			padding: 5px;
			text-align: center;
		}
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.input-item {
					.el-select .el-input.is-disabled .el-input__inner {
						cursor: default;
					}
					.el-input.is-disabled .el-input__inner {
						background-color: #ffffff;
						cursor: default;
					}
					.el-input__prefix,
					.el-input__suffix {
						display: none;
					}
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}

	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
}
</style>
